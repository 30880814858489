import React, { useState, useEffect, useRef, useContext } from "react"
import Styles from "../styles/user_update.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { navigate } from "gatsby"
import InputInfoItem from "../components/InputInfoItem";
import GeneralButton from "../components/atoms/GeneralButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import PasswordChangeTemplate from "../templates/PasswordChangeTemplate"
import SportsCreateTemplate from "../templates/SportsCreateTemplate";
import { TeamJoinTemplate } from "../templates/TeamJoinTemplate";
import SelectBoxWithInputSearch from "../components/SelectBoxWithInputSearch";
import { ToastContext } from '../context/ToastContext';
import { SportsGetType } from "../types/models/Sports";
import { SelectButtonType } from "../types/models/SelectButton";
import client from "../apis/client";
import { LeftArrowIcon } from "../icons/LeftArrowIcon";
import { UserIcon } from "../icons/UserIcon";
import { useDispatch } from "react-redux";
import { TeamRetireTemplate } from "../templates/TeamRetireTemplate";


const initialInfoList = [
    { name: "last_name", value: "", label: "名前", placeholder: "名前", errorMessage: "" },
    { name: "status_message", value: "", label: "プロフィール", placeholder: "よろしくお願いします", errorMessage: "" },
    { name: "email", value: "", label: "メールアドレス", placeholder: "sample@sample.com", errorMessage: "" },
]

const VisibleModal = {
    CHANGE_TEAM: "changeTeam",
    CHANGE_PASSWORD: "changePassword",
    CREATE_SPORTS: "createSports"
} as const
type VisibleModalType = typeof VisibleModal[ keyof typeof VisibleModal ]

const UserUpdatePage = () => {

    const [ infoList, setInfoList ] = useState( initialInfoList )
    const [ profileImage100, setProfileImage100 ] = useState( "" )
    const [ uploading, setUploading ] = useState( false )
    const [ visibleModal, setVisibleModal ] = useState<VisibleModalType | null>( null )
    const [ mySportsList, setMySportsList ] = useState<SelectButtonType<number>[]>( [] )
    const [ followSportsList, setFollowSportsList ] = useState<SelectButtonType<number>[]>( [] )
    const [ imagePath, setImagePath ] = useState<File | null>( null )
    const [ belongToTeam, setBelongToTeam ] = useState( false )
    const { addToast } = useContext( ToastContext );
    const imageInputRef = useRef<HTMLInputElement>( null )
    const dispatch = useDispatch()

    const headerOption = {
        headerTitle: "登録情報変更",
        leftIcon: <LeftArrowIcon style={ { fontSize: 20 } } onClick={ () => navigate( '/mypage' ) } />,
        // rightIcon:<GeneralButton onClick={()=>saveInfo()} title="保存" loading={uploading}/>
    }

    const getInfoList = async () => {
        const userID = localStorage.getItem( "userID" )
        const response_user = await client.get( `/api/users/me/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }
        );
        const data = await response_user.data;
        const newInfoList = infoList.map( info => {
            return {
                ...info, value: data[ info.name ]
            }
        } )
        const all_sports_list: SportsGetType[] = data.all_sports_list
        const fetchedMySportsList = all_sports_list.map( sports => ( {
            name: sports.name,
            value: sports.name,
            label: sports.label,
            isSelected: sports.my_sport
        } ) )
        const fetchedFollowSportsList = all_sports_list.map( sports => ( {
            name: sports.name,
            value: sports.name,
            label: sports.label,
            isSelected: sports.follow_sport
        } ) )
        setMySportsList( fetchedMySportsList )
        setFollowSportsList( fetchedFollowSportsList )
        setProfileImage100( data.profile_image_100 )
        setBelongToTeam( Boolean( data.community ) )
        setInfoList( newInfoList )
    }

    useEffect( () => {
        getInfoList()
    }, [] )

    const saveInfo = async () => {
        if ( uploading ) return
        let isValid = true
        infoList.forEach( input => {
            if ( input.name !== "status_message" && !input.value ) {
                isValid = false
            }
        } )
        if ( !isValid ) {
            addToast( { text: "入力されていない欄があります", type: "warn" } )
            // alert( '入力されていない欄があります' )
            return
        }

        const userID = localStorage.getItem( "userID" )
        const data = new FormData()
        infoList.forEach( info => {
            if ( info.name === "status_message" && ( !info.value || info.value.length === 0 ) ) {
                return
            }
            data.append( info.name, info.value )
        } );
        if ( imagePath ) {
            let fileName = 99 < imagePath.name.length ?
                imagePath.name.slice( imagePath.name.length - 99 ) :
                imagePath.name
            data.append( "profile_image", imagePath, fileName )
        }
        followSportsList.forEach( sports => {
            sports.isSelected && data.append( 'follow_sport', sports.value as any )
        } )
        mySportsList.forEach( sports => {
            sports.isSelected && data.append( 'my_sport', sports.value as any )
        } )

        setUploading( true )
        await client.patch( `/api/users/me/`, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then( ( res ) => {
                addToast( { text: "更新しました\n画像の反映には時間がかかる場合がございます", type: "success" } )
                // alert( '更新しました\n画像の反映には時間がかかる場合がございます' );
                setUploading( false )
                console.log( res, res.data )
                dispatch( { type: "setUserName", userName: res.data.data.last_name } )
                navigate( "/mypage" )
                // window.history.back()
                //    navigate("")
            } )
            .catch( error => {
                const data = error.response.data
                const imageError = data.profile_image
                imageError && imageError.forEach( ( e: string ) => alert( e ) )
                const newInfoList = infoList.map( info =>
                    data[ info.name ] ?
                        { ...info, errorMessage: data[ info.name ][ 0 ] } :
                        info
                )
                setUploading( false )
                setInfoList( newInfoList.slice() )
            } )
    }

    const onChangeSelectButton = ( boxName: string, buttonList: SelectButtonType<number>[] ) =>
        boxName === "mySports" ?
            setMySportsList( buttonList ) :
            setFollowSportsList( buttonList )

    const onChangeInputImage = () => {
        if ( !imageInputRef.current ) return
        const files = imageInputRef.current.files
        files && setImagePath( files[ 0 ] )
    }

    const onChangeInfoItem = ( name: string, value: string ) => {
        const newInfoList = infoList.map( info =>
            info.name === name ?
                { ...info, value: value } :
                info
        )
        setInfoList( newInfoList )
    }

    const renderModal = () => {

        if ( !visibleModal ) return

        const updateSportsList = ( name: string, pk: number ) => {
            const newMySportsList = mySportsList.concat( { value: pk, label: name, isSelected: false } )
            const newfollowSportsList = followSportsList.concat( { value: pk, label: name, isSelected: false } )
            setMySportsList( newMySportsList )
            setFollowSportsList( newfollowSportsList )
            setVisibleModal( null )
        }

        return ( {
            createSports:
                <SportsCreateTemplate
                    onSucceeded={ updateSportsList }
                    onCancel={ () => setVisibleModal( null ) } />,
            changePassword:
                <PasswordChangeTemplate
                    url="/api/users/password/change/"
                    onSucceeded={ () => setVisibleModal( null ) }
                    onCancel={ () => setVisibleModal( null ) } />,
            resetPassword:
                <PasswordChangeTemplate
                    url="/api/users/password/reset/"
                    onSucceeded={ () => setVisibleModal( null ) }
                    onCancel={ () => setVisibleModal( null ) } />,
            changeTeam:
                belongToTeam ?
                    <TeamRetireTemplate
                        onSucceeded={ () => { setVisibleModal( null ); setBelongToTeam( !belongToTeam ) } }
                        onCancel={ () => setVisibleModal( null ) } /> :
                    <TeamJoinTemplate
                        onSucceeded={ () => { setVisibleModal( null ); setBelongToTeam( !belongToTeam ) } }
                        onCancel={ () => setVisibleModal( null ) } />
        }[ visibleModal as VisibleModalType ] )
    }


    return (
        <Layout headerOption={ headerOption }>
            <>
                <SEO title="UserUpdate" />
                <div className={ Styles.container }>
                    <div className={ Styles.profile_image_container }>
                        <div className={ Styles.profile_image_wrapper }>
                            { ( imagePath || profileImage100 ) ?
                                <img className={ Styles.profile_image }
                                    onClick={ () => imageInputRef.current && imageInputRef.current.click() }
                                    src={ imagePath ? window.URL.createObjectURL( imagePath ) : profileImage100 } /> :
                                <div className={ Styles.profile_image }>
                                    <UserIcon style={ { fontSize: 40, color: "rgba(0,0,0,0.7)" } } />
                                </div> }
                            <FontAwesomeIcon
                                onClick={ () => imageInputRef.current && imageInputRef.current.click() }
                                className={ Styles.image_upload_icon }
                                icon={ faCamera }
                                style={ { fontSize: 24 } } />
                            <input type="file" ref={ imageInputRef } onChange={ () => onChangeInputImage() } />
                        </div>
                    </div>
                    { infoList &&
                        <ul>{
                            infoList.map( info =>
                                <InputInfoItem
                                    value={ info.value }
                                    multipleLines={ info.name === "status_message" }
                                    placeholder={ info.placeholder }
                                    name={ info.name }
                                    label={ info.label }
                                    key={ info.name }
                                    errorMessage={ info.errorMessage }
                                    onChange={ onChangeInfoItem } />
                            ) }
                        </ul>
                    }
                    <div>
                        <SelectBoxWithInputSearch
                            buttonList={ mySportsList }
                            onChangeSelectButton={ onChangeSelectButton }
                            type="checkbox"
                            label="自分のスポーツ"
                            buttonLabel="スポーツ"
                            boxName="mySports"
                            placeholder="スポーツを検索"
                            style={ {
                                justifyContent: "flex-end",
                                borderBottom: "1px solid rgba(0,0,0,0.12)",
                                padding: "12px 20px"
                            } } />
                        <SelectBoxWithInputSearch
                            buttonList={ followSportsList }
                            onChangeSelectButton={ onChangeSelectButton }
                            type="checkbox"
                            label="タイムラインに表示するスポーツ"
                            buttonLabel="スポーツ"
                            boxName="followSports"
                            placeholder="スポーツを検索"
                            style={ {
                                justifyContent: "flex-end",
                                borderBottom: "1px solid rgba(0,0,0,0.12)",
                                padding: "12px 20px"
                            } } />
                    </div>
                    <p className={ Styles.modal_visible_text } style={ { marginTop: 32 } } onClick={ () => setVisibleModal( VisibleModal.CREATE_SPORTS ) }>お探しのスポーツがなかった場合はこちら</p>
                    <p className={ Styles.modal_visible_text } onClick={ () => setVisibleModal( VisibleModal.CHANGE_PASSWORD ) }>パスワードを変更</p>
                    { belongToTeam && <p className={ Styles.modal_visible_text } onClick={ () => setVisibleModal( VisibleModal.CHANGE_TEAM ) }>チームから引退</p> }
                    <p style={ { textAlign: "center", marginTop: 16, marginBottom: 16 } }>
                        <GeneralButton onClick={ () => saveInfo() } style={ { width: "80%", margin: "auto", } } title="保存" loading={ uploading } />
                    </p>
                    { visibleModal && renderModal() }
                </div>
            </>
        </Layout>
    )
}





export default UserUpdatePage


