import React, { useState, useContext, FC } from "react"
import Styles from "../styles/ModalTemplate.module.scss";
import GeneralButton from "../components/atoms/GeneralButton";
import Modal from "../components/Modal";
import InputForm from "../components/atoms/InputForm";
import { ToastContext } from '../context/ToastContext';
import client from "../apis/client";
import { useDispatch } from "react-redux";


type Props = {
    onCancel: () => void,
    onSucceeded: () => void
}

export const TeamRetireTemplate: FC<Props> = ( {
    onCancel,
    onSucceeded
} ) => {

    const [ teamID, setTeamID ] = useState( "" )
    const [ errorMessage, setErrorMessage ] = useState<string[]>( [] )
    const [ teamRetireing, setTeamRetireing ] = useState( false )
    const { addToast } = useContext( ToastContext );
    const dispatch = useDispatch()


    const submit = async () => {
        if ( teamRetireing ) return
        if ( !teamID ) {
            addToast( { text: "入力されていない欄があります", type: "warn", position: 'center', } )
            return
        }
        const data = new FormData()
        data.append( "idname", teamID )
        setTeamRetireing( true )
        await client.patch( "/api/team/retire/", data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then( ( res ) => {
                setTeamRetireing( false )
                addToast( {
                    text: '引退しました',
                    type: "success",
                    position: 'center'
                } )
                dispatch( { type: "setTeamData", teamData: null } )
                onSucceeded()
            } )
            .catch( error => {
                const data = error.response.data
                setTeamRetireing( false )
                if (data.teamID){
                    setErrorMessage( data.teamID )
                }
                else if(data.detail){
                    setErrorMessage(data.detail)
                }
            } )
    }

    return (
        <Modal visible={ true } closeModal={ () => { } } closeModalIconVisible={ false }>
            <>
                <InputForm
                    autoFocus
                    label="チームID"
                    name="idname"
                    value={ teamID }
                    placeholder="確認のためチームIDをご入力ください"
                    type="text"
                    labelStyle={ { color: "#fff" } }
                    errorMessage={ errorMessage }
                    onChange={ ( name: string, value: string ) => setTeamID( value ) }
                />
                <div className={ Styles.button_container }>
                    <GeneralButton
                        title="キャンセル"
                        onClick={ onCancel }
                        transparent />
                    <GeneralButton
                        title={ "引退する" }
                        loading={ teamRetireing }
                        onClick={ submit } />
                </div>
            </>
        </Modal>
    );
}

